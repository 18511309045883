import React, { useCallback, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  CircularProgressProps,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import EngagementService from 'services/api/EngagementService';
import EngagementReport, { CustomerFunction } from 'store/types/EngagementReport';
import useRequest from 'hooks/useRequest';
import Spinner from 'components/shared/Spinner';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { NUMBER_DATE_FORMAT, getStringValue } from 'util/Format';
import Card from 'components/shared/Card';
import ClearIcon from '@mui/icons-material/Clear';

import commonStyles from 'styles/common.module.scss';
import styles from './IndividualReportSection.module.scss';
import classNames from 'classnames';
import { getFormattedNumber } from 'util/Payment';

interface IndividualReportSectionProps {
  id: string;
  removeRecord: (id: string) => void;
}

const progressBarProps: CircularProgressProps = {
  variant: 'determinate',
  size: 200,
  thickness: 6,
  color: 'primary',
};

const groupByCustomerFunction = (list: CustomerFunction[], keyGetter: any): Map<string, CustomerFunction[]> => {
  const map = new Map<string, CustomerFunction[]>();
  if (!list) return map;

  list.forEach((item: any) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

const IndividualReportSection: React.FunctionComponent<IndividualReportSectionProps> = ({ id, removeRecord }) => {
  const getProductDetailsRequest = useMemo(() => (id ? () => EngagementService.getReport(id) : undefined), [id]);
  const { data, loading, error } = useRequest<EngagementReport>(getProductDetailsRequest);
  const [selectedEvent, setSelectedEvent] = useState<string>('');

  const handleRemoveClick = useCallback(() => {
    removeRecord(id);
  }, [id, removeRecord]);

  const handleEventChange = (event: SelectChangeEvent) => {
    setSelectedEvent(event.target.value as string);
  };

  const customerFunctionGroups: [string, CustomerFunction[]][] = useMemo(() => {
    return data?.customerFunctions
      ? Array.from(
          groupByCustomerFunction(
            data.customerFunctions,
            (customerFunction: CustomerFunction) => customerFunction.functionName
          )
        )
      : [];
  }, [data?.customerFunctions]);

  return (
    <Spinner loading={loading}>
      <Card contentClassName={styles.cardContent}>
        {error && (
          <Alert severity={'error'} className={commonStyles.alert}>
            {error}
          </Alert>
        )}
        <Grid {...defaultGridContainerProps} spacing={1} className={styles.infoWrapper}>
          <Grid {...defaultGridItemProps} className={styles.nameWrapper} md={8} lg={9} xl={10}>
            <h3 className={styles.name}>{data?.name}</h3>
          </Grid>
          <Grid {...defaultGridItemProps} className={styles.removeButton} md={4} lg={3} xl={2}>
            <Tooltip title="Remove">
              <IconButton aria-label="add" color={'secondary'} onClick={handleRemoveClick}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid {...defaultGridItemProps}>
            <span className={styles.label}>{'Subsidiary: '}</span>
            <span>{data?.subsidiary}</span>
          </Grid>
          <Grid {...defaultGridItemProps}>
            <span className={styles.label}>{'Member since: '}</span>
            <span>{getStringValue(data?.memberSince?.toString(), NUMBER_DATE_FORMAT)}</span>
          </Grid>
          {data?.certifiedOwnedBusinessType && (
            <Grid {...defaultGridItemProps}>
              <span className={styles.label}>{'Certified owned business type: '}</span>
              <span>{data?.certifiedOwnedBusinessType}</span>
            </Grid>
          )}
          <Grid {...defaultGridItemProps}>
            <span className={styles.label}>{'Employee count: '}</span>
            <span>{data?.employeeCount}</span>
          </Grid>
          <Grid {...defaultGridItemProps}>
            <span className={styles.label}>{'Volunteer count: '}</span>
            <span>{data?.volunteerCount}</span>
          </Grid>
          <Grid {...defaultGridItemProps}>
            <span className={styles.label}>{'Total purchases: '}</span>
            <span>{data?.purchaseCount}</span>
          </Grid>
          <Grid {...defaultGridItemProps}>
            <span className={styles.label}>{'Total purchase amount: '}</span>
            <span>$ {data?.purchaseAmount}</span>
          </Grid>
          <Grid {...defaultGridItemProps}>
            <span className={styles.label}>{'Total inquiries: '}</span>
            <span>{data?.caseCount}</span>
          </Grid>
          {customerFunctionGroups &&
            customerFunctionGroups.length > 0 &&
            customerFunctionGroups.map((functionGroup) => {
              const key = `function-list-${functionGroup[0]}`;
              return (
                <Grid key={key} {...defaultGridItemProps}>
                  <span className={styles.label}>{`${functionGroup[0]}: `}</span>
                  <span>{functionGroup[1]?.map(({ customerName }) => customerName).join(', ')}</span>
                </Grid>
              );
            })}
          {data?.primaryChapter?.name && (
            <Grid {...defaultGridItemProps}>
              <span className={styles.label}>{'Primary chapter: '}</span>
              <span>{data?.primaryChapter?.name}</span>
            </Grid>
          )}
          {data?.secondaryChapters && data.secondaryChapters.length > 0 && (
            <Grid {...defaultGridItemProps}>
              <span className={styles.label}>{'Secondary chapters: '}</span>
              <span>{data?.secondaryChapters?.map(({ name }) => name).join(', ')}</span>
            </Grid>
          )}
          {data?.marketSublist && data.marketSublist.length > 0 && (
            <Grid {...defaultGridItemProps}>
              <span className={styles.label}>{'Market Segments: '}</span>
              <span>
                {Array.from(new Set(data?.marketSublist?.map(({ industryName }) => industryName))).join(', ')}
              </span>
            </Grid>
          )}
          <Grid {...defaultGridItemProps}>
            <span className={styles.label}>{'Total Committee Participants: '}</span>
            <span>{data?.committeeRoles?.length || 0}</span>
          </Grid>
          {data?.committeeRoles && data.committeeRoles.length > 0 && (
            <Grid {...defaultGridItemProps}>
              <span className={styles.label}>{'Committees: '}</span>
              <span>
                {Array.from(new Set(data?.committeeRoles?.map(({ committeeName }) => committeeName))).join(', ')}
              </span>
            </Grid>
          )}
          <Grid {...defaultGridItemProps}>
            <span className={styles.label}>{'Events attended: '}</span>
            <span>{data?.eventAttendance?.length || 0}</span>
          </Grid>
          <Grid {...defaultGridItemProps}>
            <span className={styles.label}>{'Event attendees: '}</span>
            <span>
              {data?.eventAttendance
                ?.map(({ attendance }) => parseInt(attendance))
                .reduce((total, value) => total + value, 0) || 0}
            </span>
          </Grid>
          {data?.eventAttendance && data.eventAttendance.length > 0 && (
            <Grid {...defaultGridItemProps}>
              <div className={styles.label}>{'Event participation: '}</div>
              <Box sx={{ paddingTop: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id={`event-select-label-${id}`}>Event</InputLabel>
                  <Select
                    labelId={`event-select-label-${id}`}
                    id={`event-select-${id}`}
                    value={selectedEvent}
                    label="Event"
                    onChange={handleEventChange}
                  >
                    {data?.eventAttendance?.map(({ eventId, eventName }) => (
                      <MenuItem value={eventId} key={`distance-${eventId}`}>
                        {eventName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}
          {selectedEvent &&
            data?.eventAttendance
              ?.filter(({ eventId }) => eventId === selectedEvent)
              .map(({ eventId, attendance }) => {
                const attendancePercentage =
                  data.employeeCount > 0 ? getFormattedNumber((parseInt(attendance) / data.employeeCount) * 100) : '0';

                return (
                  <Grid
                    {...defaultGridItemProps}
                    key={`${id}-${eventId}-attendance`}
                    className={styles.wrapper}
                    justifyContent={'center'}
                  >
                    <div className={styles.chartWrapper}>
                      <CircularProgress
                        {...progressBarProps}
                        value={100}
                        className={classNames(styles.progress, styles.background)}
                      />
                      <CircularProgress
                        {...progressBarProps}
                        value={parseInt(attendancePercentage) || 0}
                        className={classNames(styles.progress, styles.value)}
                      />
                      <div className={styles.label}>
                        <span className={styles.labelCount}>{`${attendancePercentage || 0}%`}</span>
                      </div>
                    </div>
                    <div className={styles.legendWrapper}>
                      <div className={styles.label}>
                        <span className={styles.labelCount}>{attendance}</span>
                        {'Attended'}
                      </div>
                    </div>
                  </Grid>
                );
              })}
        </Grid>
      </Card>
    </Spinner>
  );
};
export default IndividualReportSection;
